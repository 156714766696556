import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useMobileOrientation } from "react-device-detect"

import ShareSwiperMobile from "./SwiperMoblie"

function ShareMobile({ swiperState, favorites }) {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])
  const orientation = useMobileOrientation().orientation

  useEffect(() => {
    window.addEventListener("resize", e => {
      setDimensions([e.target.innerWidth, e.target.innerHeight])
    })
  }, [])

  return (
    <Wrapper w={dimensions[0]} h={dimensions[1]} isPortrait={orientation === "portrait"}>
      <Title>
        <h1>
          NINE <br /> <span> ELMS</span>
          {/* {dimensions[0]}|{dimensions[1]} */}
        </h1>
      </Title>
      <Copy>
        <h1>ELEVATED LIVING</h1>
        <p>
          This is a London address where seven-star quality meets beautifully elevated living.
          Where every detail has been exquisitely crafted and brought to life. Where breathtkaing
          views stretch across to the beating heart of Europe’s most vibrant city. And where
          neighbours in the capital’s most exclusive new quarter look on enviously.
        </p>
        <button
          onClick={() => {
            swiperState[1](true)
          }}>
          VIEW YOUR FAVOURITED APARTMENTS
        </button>
      </Copy>
      <ShareSwiperMobile openState={swiperState} favorites={favorites} />
    </Wrapper>
  )
}

export default ShareMobile

const Wrapper = styled.div`
  width: ${p => `${p.w}px`};
  height: ${p => `${p.h}px`};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  background-color: black;
  transition: all 0.5s linear;
`

const Title = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 30%;
  background-color: black;

  & h1 {
    color: #eae7e2;
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: 1rem;
    padding-left: 1rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    & span {
      color: #b58762;
      padding-left: 1rem;
      font-weight: 100;
      font-size: 2rem;
      text-align: center;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -25%);
    }
  }
`

const Copy = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  background-color: black;
  background-image: url("assets/images/share/title.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10%;
  align-items: center;

  & h1 {
    color: #b58762;
    font-size: 1.8rem;
    font-weight: 100;
    width: 60%;
    text-align: center;
  }

  & p {
    color: white;
    width: 70%;
    font-family: Bliss-Light;
    text-transform: uppercase;
    text-align: center;
    line-height: 1rem;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
  }

  & button {
    margin-top: 10%;
    padding: 5%;
    font-size: 1rem;
    text-decoration: underline;
    color: white;
    background-color: #b58762;
    border: none;
    outline: none;
    cursor: pointer;
  }
`
