import React, { useState, useRef } from "react"
import styled from "styled-components/macro"
import { useStore } from "state/store"
import { AnimatePresence, motion } from "framer-motion"
import IconButton from "components/Buttons/IconButton"
import CloseIcon from "assets/images/icons/close-white.svg"
import ShareIcon from "assets/images/icons/share.svg"
import ShareDialog from "components/ShareDialog"
import FavoriteCard from "components/ApartmentCard/FavoriteCard"
import { useNavigate, useLocation } from "react-router-dom"

export default function Favorites() {
  const favorites = useStore(state => state.favorites)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [activeFavourite, setActiveFavourite] = useState(null)
  const openCards = useRef([])

  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Holder elements={favorites.length}>
      {favorites.map((favorite, i) => (
        <FavoriteCard
          key={`aptCard${i}`}
          activeFavourite={activeFavourite}
          setActiveFavourite={setActiveFavourite}
          apartment={favorite}
          index={i}
          openCards={openCards}
        />
      ))}
      {!favorites.length && (
        <NoFavorites>
          YOU <br /> HAVE NO <br /> FAVORITES YET.
        </NoFavorites>
      )}
      <ControlsHolder visible={location.pathname === "/favourites"}>
        <IconButton
          color="white"
          icon={
            favorites.length > 0 && dialogVisible
              ? CloseIcon
              : !favorites.length
              ? CloseIcon
              : ShareIcon
          }
          onClick={() => {
            favorites.length ? setDialogVisible(!dialogVisible) : navigate("/residences")
          }}
        />
      </ControlsHolder>
      <AnimatePresence>
        {dialogVisible && (
          <Drawer
            initial={{ y: "100%" }}
            exit={{ y: "100%" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.3 }}>
            <ShareDialog />
          </Drawer>
        )}
      </AnimatePresence>
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: black;
  touch-action: pan-y !important;
`

const Drawer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
`

const NoFavorites = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  text-align: center;
  font-size: 4em;
  font-family: Bliss-Light;
  color: #b4846550;
`

const ControlsHolder = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.visible ? "#b48465" : "#212121")};
  padding: 10em 2em 2em 6em;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0% 100%);
  transition: all 0.3s ease-out;
`
