/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import styled from "styled-components"
import PageTransitionHolder from "components/PageTransitionHolder"
import { useLocation } from "react-router-dom"

import { APART_PROPS } from "dataset/finder"
import { useStore } from "state/store"

import ShareSwiper from "components/ShareSwiper/ShareSwiper"
import ShareMobile from "./mobile/ShareMobile"

export default function Share() {
  const location = useLocation()
  const codedFav = location.search
  const decodedFav = atob(codedFav.substring(7)).split(",")
  const apartments = useStore(s => s.apartments)
  const favorites = apartments.filter(apt => decodedFav.some(fav => fav === apt[APART_PROPS.unit]))
  const isMobile = window.innerWidth < 700
  const [swiperOpen, setSwiperOpen] = useState(!isMobile)

  return isMobile ? (
    <ShareMobile swiperState={[swiperOpen, setSwiperOpen]} favorites={favorites} />
  ) : (
    <PageTransitionHolder>
      <Holder>
        <ShareSwiper openState={[swiperOpen, setSwiperOpen]} favorites={favorites} />
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #000;
`

const Title = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 35%;
  background-color: black;

  & h1 {
    color: #eae7e2;
    font-weight: 100;
    font-size: 8rem;
    letter-spacing: 4rem;
    padding-left: 4rem;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    & span {
      color: #b58762;
      padding-left: 4rem;
      font-weight: 100;
      font-size: 2rem;
      text-align: center;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -25%);
    }
  }
`

const Copy = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65%;
  background-color: black;
  background-image: url("assets/images/share/title.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 5%;
  align-items: center;

  & h1 {
    color: #b58762;
    font-size: 4rem;
    font-weight: 100;
    letter-spacing: 1rem;
  }

  & p {
    color: white;
    width: 50%;
    font-family: Bliss-Light;
    text-transform: uppercase;
    text-align: center;
    line-height: 2rem;
    letter-spacing: 0.2rem;
  }

  & button {
    margin-top: 4%;
    font-size: 2rem;
    text-decoration: underline;
    color: #b58762;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
