import React, { useEffect, useRef } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"
import CabinetIcon from "assets/images/icons/cabinet.png"
import WardrobeSpaceIcon from "assets/images/icons/wardrobe-space.png"
import Sections from "./Sections"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import dimensions from "dataset/dimensions"
import FloorPlate from "components/FloorPlate/FloorPlate"
import { useState } from "react"

function FloorplanNew({ section, action, isFav, apartment }) {
  const transition = useTransition(section === "FLOORPLAN", {
    from: {
      opacity: 0,
      left: "-150%",
    },
    enter: {
      opacity: 1,
      left: "0%",
      delay: 1000,
    },
    leave: {
      opacity: 0,
      left: "150%",
    },
  })

  const activeFloor = useStore(s => s.activeFloor)
  const floor = isFav ? apartment[APART_PROPS.floor] : activeFloor

  let route = ""
  route = apartment?.[APART_PROPS.unitType]?.split(" ")[1]?.toLowerCase()

  const aptDimensions = route && Object.keys(dimensions?.[route])

  const egertonSmallBeds = [
    "1008",
    "1308",
    "1608",
    "1908",
    "908",
    "1108",
    "1208",
    "1408",
    "1508",
    "1708",
    "1808",
  ]

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <FloorplanViewer route={route} />
          <Conventions>
            <div className="item">
              <span className="icon">
                <img src={CabinetIcon} alt="Cabinet" />
              </span>
              - <span>Cabinet</span>
            </div>
            <div className="item">
              <span className="icon">
                <img src={WardrobeSpaceIcon} alt="Wardrobe Space" />
              </span>
              - <span>Wardrobe Space</span>
            </div>
            <div className="item">B - Boiler</div>
            <div className="item">UC - Utility Cupboard</div>
            <div className="item">W - Wardrobe</div>
            <div className="item">W/D - Washer Dryer</div>
            <div className="item">HIU - Heat interface unit</div>
          </Conventions>
          <SideBar>
            <DimensionsInfo>
              <h1> RESIDENCE DIMENSIONS</h1>
              <Line />
              <ul>
                {aptDimensions?.map((key, i) => {
                  return (
                    <li key={`li${i}`}>
                      <h4> {i + 1} </h4>
                      <p>
                        <span>{key}</span>
                        {route &&
                        key === "BEDROOM 1" &&
                        egertonSmallBeds.includes(apartment[APART_PROPS.unit])
                          ? "3.41m x 3.37m | 11’2” x 11’1”"
                          : dimensions[route]?.[key]}
                      </p>
                    </li>
                  )
                })}
              </ul>
            </DimensionsInfo>
            <Line />
            <FloorPlateWrapper style={isFav ? { pointerEvents: "none" } : {}}>
              <FloorPlate apartment={apartment} floor={floor} isFav={isFav} />
            </FloorPlateWrapper>
            <Line />
            <SectionsWrapper>
              <Sections action={action} section={section} />
            </SectionsWrapper>
          </SideBar>
        </Wrapper>
      ),
  )
  // return null
}

function FloorplanViewer({ route }) {
  const [duplexLevel, setDuplexLevel] = useState("lower")
  const [localRoute, set] = useState(route)

  useEffect(() => {
    setTimeout(() => set(route), 400)
  }, [route])

  const transition = useTransition(route, {
    from: {
      opacity: 0,
      left: "-50%",
    },
    enter: {
      opacity: 1,
      left: "0%",
      delay: 550,
    },
    leave: {
      opacity: 0,
      left: "50%",
    },
  })

  return transition(
    (styles, loaded) =>
      loaded && (
        <div>
          <Plan style={styles} route={localRoute} duplexLevel={duplexLevel}>
            {route === "chester" && (
              <DuplexToggle>
                <div
                  className={`duplexSelector ${duplexLevel === "lower" ? "selected" : ""}`}
                  onClick={() => {
                    setDuplexLevel("lower")
                  }}>
                  Lower
                </div>
                <div
                  className={`duplexSelector ${duplexLevel === "upper" ? "selected" : ""}`}
                  onClick={() => {
                    setDuplexLevel("upper")
                  }}>
                  Upper
                </div>
              </DuplexToggle>
            )}
          </Plan>
        </div>
      ),
  )
}

export default FloorplanNew

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Plan = styled(a.div)`
  position: absolute;
  left: 15% !important;
  top: 2.5%;
  height: 85%;
  width: 57%;

  background-color: white;
  background-image: ${props =>
    `url(assets/images/finder/plans/${props.route}${
      props.duplexLevel === "upper" ? "Upper" : ""
    }.svg)`};
  background-repeat: no-repeat;
  /* background-size: ${p => {
    switch (p.route) {
      case "egerton":
        return "auto 80%"
      case "sloane":
        return "auto 68%"
      case "arundel":
        return "auto 60%"
      case "kensington":
        return "auto 60%"
      case "arundel2":
        return "auto 60%"
      case "grosvenor":
        return "auto 60%"
      case "wilton":
        return "auto 60%"
      case "bloomsbury":
        return "auto 60%"
      case "finsbury":
        return "auto 60%"
      case "belgrave":
        return "auto 60%"
      default:
        return "auto 50%"
    }
  }}; */
  background-size: ${p => {
    "auto 50%"
  }};
  background-position: ${p => {
    switch (p.route) {
      case "egerton":
        return "85% 25%"
      case "sloane":
        return "82% 50%"
      case "arundel":
        return "86% 50%"
      case "grosvenor":
        return "85% 50%"
      case "bloomsbury":
        return "90% 50%"
      case "finsbury":
        return "85% 50%"
      case "belgrave":
        return "88% 50%"
      default:
        return "80% 50%"
    }
  }};
`
const Conventions = styled.div`
  position: absolute;
  bottom: 5%;
  left: 47%;
  transform: translateX(-50%);
  color: #b58762;
  font-size: 1vw;
  font-family: Bliss-Light;
  text-align: center;
  max-width: 40em;
  height: 4em;
  white-space: pre-line;
  line-height: 1.5em;

  & span {
    filter: none !important;
  }

  .item {
    display: inline-block;
    margin-right: 10px;
  }

  .icon {
    margin-right: 5px;
    vertical-align: middle;
  }

  .icon img {
    height: 15px;
  }
`

const SideBar = styled.div`
  position: absolute;
  right: 0;
  width: 28%;
  height: 100%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  background-color: black;
`

const DimensionsInfo = styled.div`
  touch-action: auto;
  flex: 5 1;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2% 0%;
  padding-top: 0;
  /* border: 1px solid #b58762; */

  & h1 {
    font-size: 1em;
    text-align: center;
    font-weight: 100;
  }

  & ul {
    font-size: 0.85em;
    list-style: none;
    padding-right: 10px;
  }

  & li {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 2% 2%;
    gap: 3%;

    & h4 {
      width: 10%;
      aspect-ratio: 1/1;
      border: 1px solid #b58762;
      font-size: 0.8em;
      color: #b58762;
      display: grid;
      place-content: center;
      border-radius: 100%;
    }

    & p {
      display: flex;
      flex-direction: column;
      font-weight: 100;
      justify-content: center;
      font-family: Bliss-Light;
      font-size: 0.8em;

      & span {
        color: #b58762;
        font-weight: 100;
        font-family: Grande;
        font-size: 1em;
      }
    }
  }
`

const FloorPlateWrapper = styled(a.div)`
  /* border: red solid 2px; */
  /* flex: 2.2 1; */
  flex-grow: 1;
  padding: 1%;
  box-sizing: border-box;

  /* display: grid;
  place-content: center; */

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    max-width: 80%;
    /* border: orange solid 2px; */
    opacity: 1;
    filter: none;
  }
`

const SectionsWrapper = styled.div`
  flex: 1 1;
`

const Line = styled.hr`
  width: 80%;
  height: 1px;
  margin-left: 10%;
  background-color: white;
  border: none;
`

const DuplexToggle = styled.div`
  position: absolute;
  top: 2.5%;
  color: #000;
  border: none;
  right: 5%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: 0.3s;
  text-decoration-color: #b58762;

  .duplexSelector {
    cursor: pointer;
  }

  .selected {
    color: #b58762;
    text-decoration-color: #b58762;
    transition: 0.3s;
    text-decoration: underline;
  }
`
